import { Select } from 'antd';
import styled from 'styled-components';

import { DISABLED_CELL_CONTENT_CLASS, DISABLED_ROW_CLASS } from 'constants/gridConstants';

export const CellSelect = styled(Select)`
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  height: 100%;

  .ant-select-arrow,
  .ant-select-selection-item-remove,
  .ant-select-selection-overflow-item-suffix {
    display: none !important;
  }

  .ant-select-selection-overflow {
    justify-content: center;
  }

  .ant-select-selection-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    line-height: unset;
  }

  &.ant-select-multiple .ant-select-selection-item-content {
    margin: 0;
  }

  &.ant-select-multiple .ant-select-selection-overflow-item {
    display: flex;
    width: unset;
  }

  & .ant-select-selector {
    padding: 0 !important;
    height: 100% !important;
  }

  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }

  &.ant-select-multiple
    .ant-select-selection-overflow-item:not(:first-of-type):not(.ant-select-selection-overflow-item-suffix):not(
      .ant-select-selection-overflow-item-rest
    )::before {
    content: ';';
  }

  .${DISABLED_ROW_CLASS} &.ant-select-multiple ::before,
  .${DISABLED_CELL_CONTENT_CLASS} &.ant-select-multiple ::before {
    color: #aeaecc;
  }

  .maxTag {
    margin-left: 2px;
    font-size: 11px;
    opacity: 0.6;
  }
`;
