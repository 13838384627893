import React, { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Upload, Select } from 'antd';
import axios from 'axios';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { read, utils } from 'xlsx';

import { Checkbox, PrimaryButton } from 'components/UI';
import envconfig from 'config/envconfig';
import { FileType } from 'services/graphql/main';
import { FileImportType } from 'types/import-type';
import { useError } from 'services/utils';
import { getCacheVal, isTokenCloseToExpiration } from 'services/utils';

import ErrorLogsTable from './components/ErrorLogsTable';
import ImportTable from './components/ImportTable';
import { SectionTitle, StyledLabel, InfoText, DataImportSection, LeftPart, RightPart, CheckboxWrapper } from './styles';

type Props = {
  refetchData?: () => void;
};

const MassImport: React.FC<Props> = ({ refetchData }) => {
  const { t } = useTranslation('translation');
  const { addCustomError } = useError();
  const token = getCacheVal('token');

  const [importType, setImportType] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [fileType, setFileType] = useState<string>();
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string | null>(null);
  const [hasHeaderRow, setHasHeaderRow] = useState<boolean>(true);
  const [xlsxHeaders, setXlsxHeaders] = useState<{ headers: string[]; sheet: string }[]>();
  const [fileHeaders, setFileHeaders] = useState<{ name: string; value: number }[]>([]);
  const [matchedData, setMatchedData] = useState<{ ColumnIndex: number | null; Field: string }[]>([]);
  const [errorLogs, setErrorLogs] = useState<{ args: string[]; code: string; field: string; lineIndex: number }[]>();
  const [isImportLoading, setIsImportLoading] = useState<boolean>(false);

  const importTypes = Object.keys(FileImportType);

  const resetState = () => {
    setHasHeaderRow(true);
    setFileType(undefined);
    setSheets([]);
    setXlsxHeaders(undefined);
    setFileHeaders([]);
    setSelectedSheet(null);
    setErrorLogs(undefined);
  };

  const props: UploadProps = {
    id: 'fileInput',
    maxCount: 1,
    accept: '.xlsx,.csv',
    onRemove: () => {
      resetState();
      setUploadedFile(undefined);
    },
    beforeUpload: (file) => {
      resetState();
      setUploadedFile(file);

      const extension = file.name.split('.').at(-1);
      setFileType(extension);

      if (extension === 'csv') {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            results.meta.fields && setFileHeaders(results.meta.fields.map((field, i) => ({ value: i, name: field })));
          },
        });
      } else if (extension === 'xlsx') {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const wb = read(event.target.result);
            const fileSheets = wb.SheetNames;
            setSheets(fileSheets);
            setSelectedSheet(fileSheets[0]);
            if (fileSheets.length) {
              fileSheets.forEach((sheet, i) => {
                const headers = utils.sheet_to_json(wb.Sheets[fileSheets[i]], { header: 1 })[0] as string[];
                setXlsxHeaders((data) => (data ? [...data, { sheet, headers }] : [{ sheet, headers }]));
              });
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }

      return false;
    },
    fileList: uploadedFile ? [uploadedFile] : [],
  };

  const importFileHandler = async () => {
    if (matchedData.find((line) => line.ColumnIndex === null)) {
      addCustomError({ message: 'REQUIRED_FIELDS' }, 'warning');

      return;
    }

    setIsImportLoading(true);
    const data = new FormData();
    data.append('file', uploadedFile);
    const params = new URLSearchParams();
    importType && params.append('FileImportType', importType);
    params.append('HasHeaderRow', hasHeaderRow ? 'true' : 'false');
    params.append('FileType', fileType === 'csv' ? FileType.Csv : FileType.Excel);
    params.append('SheetName', selectedSheet ? selectedSheet : 'null');
    matchedData.forEach((obj) => {
      params.append('FileMappings', JSON.stringify(obj));
    });
    token && isTokenCloseToExpiration(token);
    try {
      const res = await axios.post(envconfig.fileUploadUrl + '/BulkDataImport', data, {
        headers: { 'Content-Type': 'multipart/form-data', authorization: `Bearer ${token}` },
        params,
      });
      setIsImportLoading(false);
      if (res.data.errors.length) {
        setErrorLogs(res.data.errors);
        const msg = t('importSettings.successful_import_with_errors');
        toast.warning(msg, { theme: 'colored' });
      } else {
        const msg = t('importSettings.successful_import');
        toast.success(msg, { theme: 'colored' });
      }
      refetchData && refetchData();
      setUploadedFile(undefined);
    } catch (err) {
      addCustomError(err, 'error');
      setIsImportLoading(false);
    }
  };

  useEffect(() => {
    const currentHeaders = xlsxHeaders?.find((arr) => arr.sheet === selectedSheet)?.headers;
    currentHeaders && setFileHeaders(currentHeaders.map((header, i) => ({ value: i, name: header })));
  }, [selectedSheet, xlsxHeaders]);

  useEffect(() => {
    !hasHeaderRow &&
      setFileHeaders((currentHeaders) =>
        currentHeaders.map((field, i) => ({ value: i, name: `${t('importSettings.column')} ${i + 1}` })),
      );
  }, [hasHeaderRow, t]);

  useEffect(() => {
    setErrorLogs(undefined);
  }, [importType]);

  return (
    <>
      <SectionTitle>{t('importSettings.import_file')}</SectionTitle>
      <StyledLabel htmlFor="typeSelect">{t('importSettings.import_type')}</StyledLabel>
      <Select
        id="typeSelect"
        placeholder={t('importSettings.select_type')}
        onChange={(value: string) => setImportType(value)}
        style={{ marginBottom: 8, width: 300 }}
      >
        {importTypes.map((value: string, i: number) => (
          <Select.Option key={i} value={FileImportType[value as keyof typeof FileImportType]}>
            {t(`importSettings.${value}`)}
          </Select.Option>
        ))}
      </Select>
      <StyledLabel htmlFor="fileInput">{t('importSettings.import_file_format')}</StyledLabel>
      <Upload {...props}>
        <PrimaryButton icon={<UploadOutlined />} text={t('importSettings.upload')} />
      </Upload>
      {fileType === 'xlsx' && sheets.length && (
        <>
          <StyledLabel htmlFor="sheetSelect" style={{ marginTop: 8 }}>
            {t('importSettings.select_sheet')}
          </StyledLabel>
          <Select
            id="sheetSelect"
            value={selectedSheet}
            onChange={(value: string) => setSelectedSheet(value)}
            style={{ marginBottom: 5, width: 300 }}
          >
            {sheets?.map((value: string, i: number) => (
              <Select.Option key={i} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </>
      )}
      {uploadedFile && (
        <CheckboxWrapper>
          <Checkbox onChange={(e) => setHasHeaderRow(e.target.checked)} checked={hasHeaderRow} />
          <StyledLabel>{t('importSettings.header_row')}</StyledLabel>
        </CheckboxWrapper>
      )}
      <SectionTitle style={{ marginTop: 8 }}>{t('importSettings.import_data')}</SectionTitle>
      {importType ? (
        <DataImportSection>
          <LeftPart>
            <StyledLabel>{t('importSettings.column_selection')}</StyledLabel>
            <ImportTable
              fileHeaders={fileHeaders}
              importType={importType}
              matchedData={matchedData}
              setMatchedData={setMatchedData}
            />
            {uploadedFile && (
              <PrimaryButton
                textKey="importSettings.submit"
                onClick={importFileHandler}
                style={{ display: 'block' }}
                loading={isImportLoading}
              />
            )}
          </LeftPart>
          <RightPart>
            <StyledLabel>{t('importSettings.import_details')}</StyledLabel>
            <ErrorLogsTable errorLogs={errorLogs} />
          </RightPart>
        </DataImportSection>
      ) : (
        <InfoText>{t('importSettings.import_your_file')}</InfoText>
      )}
    </>
  );
};

export default MassImport;
